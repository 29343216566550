import { createSlice } from '@reduxjs/toolkit';

const testSlice = createSlice({
  name: 'testing',
  initialState: {
    data: [],
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setData } = testSlice.actions;
export default testSlice.reducer;
