import { motion, AnimatePresence } from 'framer-motion';
import React, { useState } from 'react'
import { ArrowRight } from '../../../Assets/images';
import "./styles.css"
import { useNavigate } from 'react-router-dom';

const TechTacticalsFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const techtacticalsFaqs = [ 
    { idx: 1, question: "What is Tech Tacticals ?", answer: "Tech Tacticals is a digital agency specializing in web development, UI/UX design, and digital marketing services. We are dedicated to crafting exceptional online experiences for our clients and helping them achieve their digital goals.", },
    { idx: 2, question: "What benefits can be provided by Tech Tacticals ?", answer: "Tech Tacticals offers a range of valuable benefits for your digital needs. We specialize in enhancing your online visibility and helping you reach your target audience effectively. Our team provides customized solutions that are tailored to your specific requirements. We're experts in designing innovative and user-friendly web interfaces that leave a lasting impression. Furthermore, we help businesses grow in the digital realm, expanding market reach, improving brand reputation, and increasing turnover through effective digital strategies.", },
    { idx: 3, question: "How long does it take to complete a project with Tech Tacticals ?", answer: "Project timelines can vary depending on the scope and complexity of the project. We work closely with our clients to establish clear project timelines during the briefing phase. Our team is committed to delivering projects on time while maintaining the highest quality standards.", },
    { idx: 3, question: "What to do if you are not satisfied with the work of the Tech Tacticals ?", answer: "We prioritize client satisfaction, and we're here to address any concerns. If you are not satisfied with our work, please reach out to our customer support team, and we will do our best to understand your concerns and work towards a solution. Our goal is to ensure you're happy with the final results, and we're committed to making any necessary adjustments to achieve that. Your feedback is important to us, and we value the opportunity to address your specific needs.", },
  ]

  return (
    <div className="techtacticals-faq">
      <div className="heading">Frequently Asked Questions</div>

      <div className="accordions-container">
        {techtacticalsFaqs.map((faq, index) => (
          <div key={index} className="accordion-row">
            <div onClick={() => toggleAccordion(index)} className="accordion-summary">
              <div className="accordion-expand">
                <p>{faq.question}</p>
                <img src={ArrowRight} alt="arrow" className="rotate-arrow" />
              </div>
            </div>
            <AnimatePresence>
              {activeIndex === index && (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                >
                  <div className="accordion-detail">{faq.answer}</div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>

      <button onClick={() => navigate("/lets-talk")} className="techtacticals-button-filled">Have some more questions ?</button>
    </div>
  )
}

export default TechTacticalsFaq
