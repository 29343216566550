import React from 'react'
import Swiper from '../../Atoms/Swiper'
import { ReviewProfile, ReviewQoutes } from '../../../Assets/images'
import "./styles.css"

const ONE_CM_IN_PIXELS = 37.7952755906;
const NO_OF_CARDS_ON_MOBILE = 1;
const NO_OF_CARDS_ON_WEB = 3;

const TechTacticalsReviews = () => {
  const reviews = [
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { image: ReviewProfile, name: "lorem", designation: "ipsum", review: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
  ]

  const isMobileView = window.innerWidth < (20 * ONE_CM_IN_PIXELS);

  const ReviewCard = ({ name, designation, review, image }) => {
    return (
      <div className="review-card">
        <img src={image} alt="" className="user-image" />
        <img src={ReviewQoutes} alt="qoutes" className="review-qoutes" />
        <div className="review">
          { review }
        </div>
        <div className="review-user">
          <div className="user-details">
            <div className="name">{ name }</div>
            <div className="designation">{ designation }</div>
          </div>
          <div className="rating">
            *****
          </div>
        </div>
      </div>
    )
  }

  const createReviewCards = () => {
    const allReviews = reviews.map((rev, idx) => <ReviewCard key={idx} {...rev} />);
    
    const chunkSize = isMobileView ? NO_OF_CARDS_ON_MOBILE : NO_OF_CARDS_ON_WEB;
    const reviewChunks = [];
    for (let i = 0; i < allReviews.length; i += chunkSize) {
      reviewChunks.push(allReviews.slice(i, i + chunkSize));
    }
  
    return reviewChunks.map((chunk, idx) => (
      <div className="review-set-cover" key={idx}>
        {chunk}
      </div>
    ));
  }

  return (
    <div className="techtacticals-workflow techtacticals-services reviews">
      <div className="content-box">
        <div className="heading-and-desc">
          <div className="title-and-heading">
            <p className="title">TESTIMONIALS</p>
            <p className="heading">What People <span className="text-highlight">Say</span> <br /> About us</p>
          </div>
          <div className="description">
            We're a specialized digital agency, excelling in website development, UI/UX design, digital marketing, and comprehensive web services. Our skilled team collaborates closely with clients to provide tailored solutions meeting their unique needs.
          </div>
        </div>
        <div className="review-container">
          <Swiper components={createReviewCards()} showPrevButton showNextButton />
        </div>
      </div>
    </div>
  )
}

export default TechTacticalsReviews
