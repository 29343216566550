import React, { useEffect } from "react"
import "./styles.css"

import { Footer } from "../../Components/Molecules";
import { LetsTalkVideo, LetsTalkVideoMp4 } from "../../Assets/videos";

const TechTacticalsLetsTalk = () => {
  useEffect(() => {
    console.log("first")
    if ('scrollBehavior' in document.documentElement.style) {
      document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // If smooth scrolling is not supported, fallback to instant scrolling
      document.documentElement.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    }
  }, [])
  
  return (
    <div className="techtacticals-lets-talk">
      <div className="techtacticals-lets-talk-page">

        <div className="techtacticals-side-video">
          <video loop autoPlay muted>
            <source src={LetsTalkVideoMp4} type="video/mp4" />
          </video>
        </div>

        <div className="techtacticals-lets-talk-form-container">
          <p className="techtacticals-lets-talk-title">
            Let's <span className="text-highlight">Talk</span>
          </p>
          <p className="techtacticals-lets-talk-description">
            Drop us a message, and we'll promptly connect with you. Your needs are our priority.
          </p>

          <div className="techtacticals-lets-talk-form">
            <label>
              <p>Message</p>
              <textarea placeholder="Enter your message or query ..." />
            </label>

            <label>
              <p>Email</p>
              <input placeholder="Enter your email ..." />
            </label>

            <label>
              <p>Contact Number</p>
              <input placeholder="Enter your mobile / telephone number ..." />
            </label>

            <button className="techtacticals-button-filled techtacticals-lets-talk-submit-button">Send Message</button>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default TechTacticalsLetsTalk
