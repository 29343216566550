// Routes that only require a Login to be accessible.

import { HomePage, TechTacticalsLetsTalk } from "../Pages";

const AuthenticationRoute = [
  { path: "/", element: <HomePage /> },
  { path: "/lets-talk", element: <TechTacticalsLetsTalk /> },
];

export default AuthenticationRoute;
  