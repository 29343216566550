import React from 'react'
import { TechTacticalsLogo } from '../../../Assets/images'
import { Link } from 'react-router-dom'
import "./styles.css"

const Footer = () => {
  const techtacticalsLinks = [
    { column: 1, name: "Quick Link", links: [
      { count: 1, name: "Home", path: "/" },
      { count: 2, name: "About", path: "/about" },
      { count: 3, name: "Project", path: "/project" },
    ]},
    { column: 2, name: "Company", links: [
      { count: 1, name: "About", path: "/about" },
      { count: 2, name: "Contact", path: "/contact" },
    ]},
    { column: 3, name: "Support", links: [
      { count: 1, name: "FAQs", path: "/faq" },
      { count: 2, name: "Support", path: "/support" },
      { count: 3, name: "Privacy Policy", path: "/privacy" },
      { count: 3, name: "Terms & Agreement", path: "/terms" },
    ]},
    { column: 4, name: "Office", changeDirectForMob: true, links: [
      { count: 1, name: <>84/A Naveen Nagar, <br /> Bhopal, Madhya Pradesh, <br /> India, 462001</>, icon: "" },
      { count: 2, name: "contact@techtacticals.com", icon: "" },
    ]},
  ]

  return (
    <div className="techtacticals-footer">
      <div className="lets-build-together">
        <p>Empowering Your Digital <br /> Future</p>
        <button className="techtacticals-button-filled">Let's Talk</button>
      </div>

      <div className="techtacticals-info">
        <div className="about-techtacticals">
          <img src={TechTacticalsLogo} alt="Tech Tacticals" />
          <div className="description">
            We are a creative agency with several services that focused on quality and innovations for your business
          </div>
        </div>
        <div className="techtacticals-links">
          {techtacticalsLinks.map((section, idx) => (
            <div className="link-column" key={idx}>
              <p className="column-heading">{ section.name }</p>
              <div className={`section-links ${section.changeDirectForMob ? "flex-column" : ""}`}>
                {section.links.map((link, index) => (
                  <span key={index} className="link">
                    { link.icon ? <img src="" alt="icon" /> : null}
                    { link.path ? <Link to={link.path}>{ link.name }</Link> : <p>{ link.name }</p> }
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  )
}

export default Footer
