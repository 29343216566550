import React from 'react'
import "./styles.css"
import { ArrowUpRight, WhyChooseTechTacticals } from '../../../Assets/images'

const WhyTechTacticals = () => {
  return (
    <div className="why-choose-techtacticals">
        <div className="why-choose-techtacticals-content">
          <img src={WhyChooseTechTacticals} alt="" />
          <div className="content-box">
            <p className="title">ABOUT US</p>
            <p className="heading">Why You Should <br /> Choose <span className="text-highlight">TechTacticals</span></p>
            <p className="description">As a specialized digital agency, we excel in web design, development, hosting, and dynamic digital marketing services, including captivating social media ads and strategic ad campaigns. Our experienced team of tech-savvy professionals works passionately and closely with clients to deliver customized solutions that precisely meet their specific needs. With a keen focus on user experience, we craft stunning web designs that leave a lasting impact on your audience, driving higher engagement and conversions.</p>
            <button className="techtacticals-button-text">
              Learn More
              <img src={ArrowUpRight} alt="" />
            </button>
          </div>
        </div>
      </div>
  )
}

export default WhyTechTacticals
