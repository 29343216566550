import React from "react";
import "./styles.css";

import { LandingPage } from "../../Components/Organisms";

const HomePage = () => {

  return (
    <div className="homepage">
      <LandingPage />
    </div>
  )
}

export default HomePage
