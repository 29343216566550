import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { ArrowRight, img } from '../../../Assets/images';
import './styles.css';

const Swiper = ({ components, showPrevButton, showNextButton }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const controls = useAnimation();

  const swiperMovesRight = useRef(true);
  const autoplaySlides = useRef(true);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
    controls.start({ x: -index * 100 });
  };

  const handleNextSlide = () => {
    const newIndex = (activeIndex + 1) % components.length;
    handleSlideChange(newIndex);
  };

  const handlePrevSlide = () => {
    const newIndex = activeIndex === 0 ? components.length - 1 : activeIndex - 1;
    handleSlideChange(newIndex);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    autoplaySlides.current = false;
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      const deltaX = touchEndX - touchStartX;

      if (deltaX > 50) {
        handlePrevSlide();
      } else if (deltaX < -50) {
        handleNextSlide();
      }

      setTouchStartX(null);
      setTouchEndX(null);
    }
  };

  useEffect(() => {
    if (!autoplaySlides.current) return;

    if (activeIndex === components.length - 1) swiperMovesRight.current = false;
    else if (activeIndex === 0) swiperMovesRight.current = true;

    const interval = setTimeout(() => {
      if (swiperMovesRight.current) handleNextSlide();
      else handlePrevSlide();
    }, 2000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div
      className="swiper-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {showPrevButton ? (
        <button
          className="swiper-btn-left"
          onClick={() => {
            autoplaySlides.current = false;
            handlePrevSlide();
          }}
        >
          <img src={ArrowRight} alt="" className="rotate-arrow" />
        </button>
      ) : null}
      {showNextButton ? (
        <button
          className="swiper-btn-right"
          onClick={() => {
            autoplaySlides.current = false;
            handleNextSlide();
          }}
        >
          <img src={ArrowRight} alt="" />
        </button>
      ) : null}
      <div
        className="swiper-wrapper"
        style={{
          height: "360px",
          width: `${components.length * 101}%`,
          display: "flex",
          transform: `translateX(-${activeIndex * (100 / components.length)}%)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {components.map((component, index) => (
          <motion.div
            key={index}
            className="swiper-slide"
            style={{
              flex: `0 0 ${100 / components.length}%`,
              // backgroundImage: `url(${image})`,
              // backgroundSize: "cover",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {component}
          </motion.div>
        ))}
      </div>
      <div className="swiper-pagination">
        {components.map((_, index) => (
          <span
            key={index}
            className={`swiper-pagination-bullet${
              index === activeIndex ? " active" : ""
            }`}
            onClick={() => {
              handleSlideChange(index);
              autoplaySlides.current = false;
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Swiper;
