import React from 'react'
import { ArrowUpRight, img1, img2, img3 } from '../../../Assets/images'
import "./styles.css"

const TechTacticalsProjects = () => {
  const ourProjects = [
    { count: 1, image: img1, title: "Lorem ipsum dolor sit amet", description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
    { count: 1, image: img2, title: "Lorem ipsum dolor sit amet", description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore", class: "add-margin" },
    { count: 1, image: img3, title: "Lorem ipsum dolor sit amet", description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore" },
  ]

  return (
    <div className="discover-our-projects techtacticals-services">
        <div className="content-box">

          <div className="heading-and-desc">
            <div className="title-and-heading">
              <p className="title">OUR PROJECTS</p>
              <p className="heading">Discover Our <br /> <span className="text-highlight">Selected</span> Projects</p>
            </div>
            <div className="description">
              <p>Empowering clients with comprehensive website and digital marketing services to enhance visibility and target audience engagement. Backed by a track record of successful collaborations, our achievements include:</p>
              <button className="techtacticals-button-text">
                Discover More
                <img src={ArrowUpRight} alt="" />
              </button>
            </div>
          </div>

          <div className="product-cards-container">
            { ourProjects.map((project, index) => (
              <div className={`project-card ${project.class || ""}`} key={index}>
                <img src={project.image} alt="" />
                <p className="title">{ project.title }</p>
                <p className="project-description">{ project.description }</p>
              </div>
            )) }
          </div>

        </div>
      </div>
  )
}

export default TechTacticalsProjects
