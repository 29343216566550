import React, { useState } from "react";
import "./styles.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CrossIcon, HamburgerIcon, TechTacticalsLogo } from "../../../Assets/images";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const appLinks = [
    { idx: 1, name: "Home", path: "/" },
    { idx: 2, name: "Services", path: "/services" },
    { idx: 3, name: "Projects", path: "/projects" },
    { idx: 4, name: "About", path: "/about" },
  ]

  const [ showDrawer, setShowDrawer ] = useState(false);

  return (
    <div className="navbar">
      <div className="techtacticals-logo">
        <Link to="/">
          <img src={TechTacticalsLogo} alt="" />
        </Link>
      </div>
      <div className="techtacticals-menubar">
        { appLinks.map(link => (
          <Link key={link.idx} className={link.path === location.pathname ? "link-underline link-active" : ""} to={link.path}>
            { link.name }
          </Link>
        )) }
        <img src="" alt="" />
      </div>
      <div className="techtacticals-support">
        <button className="techtacticals-button-filled">Get Started</button>
        <button onClick={() => navigate("/lets-talk")} className="techtacticals-button-outlined">Let's Talk</button>
      </div>
      <button className="drawer-button" onClick={() => setShowDrawer(!showDrawer)}>
        <img src={HamburgerIcon} alt="" />
      </button>

      <div className={`drawer-menu ${!showDrawer ? "remove-drawer" : "show-drawer"}`}>
        <div className="techtacticals-drawer">
          <button className="drawer-close-button" onClick={() => setShowDrawer(false)}>
            <img src={CrossIcon} alt="" />
          </button>
          <div className="drawer-links">
            { appLinks.map(link => (
              <Link key={link.idx} className={link.path === location.pathname ? "drawer-link-active" : ""} onClick={() => setShowDrawer(!showDrawer)} to={link.path}>
                - { link.name }
              </Link>
            )) }
          </div>

          <div className="drawer-support-buttons">
            <button className="techtacticals-button-filled">Get Started</button>
            <button onClick={() => navigate("/lets-talk")} className="techtacticals-button-outlined">Let's Talk</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
