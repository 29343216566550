import { useRoutes } from "react-router-dom";
import AuthenticationRoutes from "./Authentication";

const AppRoutes = () => {
  const element = useRoutes([
    ...AuthenticationRoutes,

    { path: "*", element: <div>Not found !</div> },
  ]);

  return element;
};

export default AppRoutes;
