import React from 'react'
import "./styles.css"
import LandingScreen from '../../Molecules/LandingScreen'
import { Footer, TechTacticalsFaq, TechTacticalsProjects, TechTacticalsReviews, TechTacticalsServices, TechTacticalsWorkFlow, WhyTechTacticals } from '../../Molecules'

const LandingPage = () => {
  return (
    <>
      <LandingScreen />
      <WhyTechTacticals />
      <TechTacticalsServices />
      <TechTacticalsWorkFlow />
      <TechTacticalsProjects />
      <TechTacticalsReviews />
      <TechTacticalsFaq />
      <Footer />
    </>
  )
}

export default LandingPage
