import React from 'react'
import { HowDoWeWorkIllustration } from '../../../Assets/images'
import "./styles.css"

const TechTacticalsWorkFlow = () => {
  return (
    <div className="techtacticals-workflow techtacticals-services">
      <div className="content-box">
        <div className="heading-and-desc">
          <div className="title-and-heading">
            <p className="title">HERE ARE THE STEPS</p>
            <p className="heading">How do We <span className="text-highlight">Work?</span></p>
          </div>
          <div className="description">
            We have structured work process to ensure that the projects handled can be completed properly and according to your need. Here are some common stages in the work process that we offer:
          </div>
        </div>
        <div className="workflow-image">
          <div className='workflow-background-img'></div>
        </div>
      </div>
    </div>
  )
}

export default TechTacticalsWorkFlow
