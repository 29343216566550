import React from 'react'
import { ArrowRightWhite, DigitalMarketingIllustration, WebDevelopmentIllustration, WordpressShopifyIllustration } from '../../../Assets/images'
import "./styles.css"

const TechTacticalsServices = () => {
  const servicesOffered = [
    {
      idx: 1, nm: <>Development from Scratch</>, desc: "Elevating Brands Through Expert Web Development & UI/UX Designing", image: WebDevelopmentIllustration,
    },
    { idx: 2, nm: <>Digital Marketing & Social Media Ads</>, desc: "Amplify Your Brand with Results-Driven Digital Marketing", image: DigitalMarketingIllustration, class: "services-margin" },
    { idx: 3, nm: <>WordPress, Shopify & Wix</>, desc: "Empowering Online Ventures through WordPress & Shopify Expertise", image: WordpressShopifyIllustration, },
  ]

  return (
    <div className="techtacticals-services">
      <div className="content-box">
        <div className="heading-and-desc">
          <div className="title-and-heading">
            <p className="title services">OUR SERVICES</p>
            <p className="heading">What <span className="text-highlight">Services</span> <br /> We're Offering</p>
          </div>
          <div className="description services">
            We are dedicated to empowering businesses by enhancing their online visibility, reputation, and market reach. Ensure effective and tailored solutions for your business growth. Partner with us today, and let's take your brand to new heights in the digital realm!
          </div>
        </div>
        <div className="services-offered-container">
          {servicesOffered.map((service, index) => (
            <div className={`service-card ${service.class || ""}`} key={index}>
              <img src={service.image} alt="" />
              <div className="service-details">
                <p className="name">{service.nm}</p>
                <p className="service-desc">{service.desc}</p>

                <button className="techtacticals-button-filled">
                  Explore
                  <img src={ArrowRightWhite} alt="" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TechTacticalsServices
