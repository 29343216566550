import React from "react";
import "./App.css";

import { Navbar } from "./Components/Atoms";
import AppRoutes from "./Routes";

function App() {
  return (
    <div className="techtacticals-app">
      <Navbar />

      <AppRoutes />
    </div>
  );
}

export default App;
