import React, { useEffect, useState } from 'react'
import "./styles.css"
import Swiper from '../../Atoms/Swiper'
import { img, img1, img2 } from '../../../Assets/images'
import { TechTacticalsVideo, TechTacticalsVideoMp4 } from '../../../Assets/videos'
import { useNavigate } from 'react-router-dom'

const LandingScreen = () => {
  const [hideGif, setHideGif] = useState(false);
  const [resetStyles, setResetStyles] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideGif(true);
    }, 700);

    const stylesTimeout = setTimeout(() => {
      setResetStyles(true);
      document.body.style.backgroundColor = "black"
    }, 2700);

    return () => {
      clearTimeout(timeout);
      clearTimeout(stylesTimeout);
    };
  }, []);

  return (
    <div className="techtacticals-landing-page">
      {!hideGif ? (
        <img
          src="/animation.gif"
          className="techtacticals-droplet-animation"
          alt="techtacticals: home page loading animation"
        />
      ) : null}

      <div className={resetStyles ? "techtacticals-background-fill no-radius" : "techtacticals-background-fill"}></div>

      {resetStyles ? (
        <div className="landing-page-content">
          <div className="techtacticals-video">
            <video loop='loop' autoPlay='autoPlay' muted='muted'>
              <source src={TechTacticalsVideoMp4} type="video/mp4" />
            </video>
            <div className="video-overlay"></div>
            <div className="video-overlay-content">
              <p>We Are
                <span className="text-highlight"> Creative</span>
                <br />
                <span className="second-line">Digital Agency
                </span>
                <br />
                <span className='sub-head'>
                  We help see the opportunities <br />
                  digitalisation brings
                </span>
              </p>
              <button onClick={() => navigate("/lets-talk")} className="techtacticals-button-filled">Let's Talk</button>

              <div className="techtacticals-description">
                <p>we are creative agency with several services that focused on quality and innovations for your business, Scroll for more</p>
              </div>
            </div>
          </div>

          {/* <div className="gallery-view-container">
              <Swiper components={[ <img src={img} alt="" />, <img src={img1} alt="" />, <img src={img2} alt="" /> ]} />
            </div> */}
        </div>
      ) : null}
    </div>
  )
}

export default LandingScreen
